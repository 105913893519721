/* ==========================================================================
 * #COLORS
 * --------------------------------------------------------------------------
 * Color utilities. Use them to add/modify background and text colors.
 * ========================================================================== */

.u-color-gray100 { color: $color-gray100; }
.u-color-gray200 { color: $color-gray200; }
.u-color-gray300 { color: $color-gray300; }
.u-color-gray400 { color: $color-gray400; }
.u-color-gray500 { color: $color-gray500; }
.u-color-gray600 { color: $color-gray600; }
.u-color-gray700 { color: $color-gray700; }
.u-color-gray800 { color: $color-gray800; }
.u-color-gray900 { color: $color-gray900; }

.u-color-blue100 { color: $color-blue100; }
.u-color-blue200 { color: $color-blue200; }
.u-color-blue300 { color: $color-blue300; }
.u-color-blue400 { color: $color-blue400; }
.u-color-blue500 { color: $color-blue500; }
.u-color-blue600 { color: $color-blue600; }
.u-color-blue700 { color: $color-blue700; }
.u-color-blue800 { color: $color-blue800; }
.u-color-blue900 { color: $color-blue900; }

.u-color-gold100 { color: $color-gold100; }
.u-color-gold200 { color: $color-gold200; }
.u-color-gold300 { color: $color-gold300; }
.u-color-gold400 { color: $color-gold400; }
.u-color-gold500 { color: $color-gold500; }
.u-color-gold600 { color: $color-gold600; }
.u-color-gold700 { color: $color-gold700; }
.u-color-gold800 { color: $color-gold800; }
.u-color-gold900 { color: $color-gold900; }


.u-bgColor-gray100 { background-color: $color-gray100; }
.u-bgColor-gray200 { background-color: $color-gray200; }
.u-bgColor-gray300 { background-color: $color-gray300; }
.u-bgColor-gray400 { background-color: $color-gray400; }
.u-bgColor-gray500 { background-color: $color-gray500; }
.u-bgColor-gray600 { background-color: $color-gray600; }
.u-bgColor-gray700 { background-color: $color-gray700; }
.u-bgColor-gray800 { background-color: $color-gray800; }
.u-bgColor-gray900 { background-color: $color-gray900; }

.u-bgColor-blue100 { background-color: $color-blue100; }
.u-bgColor-blue200 { background-color: $color-blue200; }
.u-bgColor-blue300 { background-color: $color-blue300; }
.u-bgColor-blue400 { background-color: $color-blue400; }
.u-bgColor-blue500 { background-color: $color-blue500; }
.u-bgColor-blue600 { background-color: $color-blue600; }
.u-bgColor-blue700 { background-color: $color-blue700; }
.u-bgColor-blue800 { background-color: $color-blue800; }
.u-bgColor-blue900 { background-color: $color-blue900; }

.u-bgColor-gold100 { background-color: $color-gold100; }
.u-bgColor-gold200 { background-color: $color-gold200; }
.u-bgColor-gold300 { background-color: $color-gold300; }
.u-bgColor-gold400 { background-color: $color-gold400; }
.u-bgColor-gold500 { background-color: $color-gold500; }
.u-bgColor-gold600 { background-color: $color-gold600; }
.u-bgColor-gold700 { background-color: $color-gold700; }
.u-bgColor-gold800 { background-color: $color-gold800; }
.u-bgColor-gold900 { background-color: $color-gold900; }
