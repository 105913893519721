/* ==========================================================================
 * #SPACING
 * --------------------------------------------------------------------------
 * Global spacing units used to add space between UI elements.
 *
 * Usage scale:
 *
 * *-micro
 * *-smallest
 * *-smaller
 * *-small
 * *-base
 * *-large
 * *-larger
 * *-largest
 * *-jumbo
 * ========================================================================== */

$spacing-base: 20px !default;

$spacing-small: ($spacing-base / 2) !default; // 10px
$spacing-sm: ($spacing-base / 2) !default; // 10px
$spacing-xs: ($spacing-base - 15) !default; // 5px
$spacing-smaller: ($spacing-base - 15) !default; // 5px
$spacing-2xs: 2px !default;
$spacing-smallest: 2px !default;

$spacing-large: ($spacing-base + 10) !default; // 30px
$spacing-lg: ($spacing-base + 10) !default; // 30px
$spacing-larger: ($spacing-base * 2) !default; // 40px
$spacing-xl: ($spacing-base * 2) !default; // 40px
$spacing-largest: ($spacing-base + 30) !default; // 50px
$spacing-2xl: ($spacing-base + 30) !default; // 50px
$spacing-huge: ($spacing-base + 40) !default; // 60px
$spacing-3xl: ($spacing-base + 40) !default; // 60px
$spacing-4xl: ($spacing-base + 40) !default; // 60px
$spacing-5xl: ($spacing-base + 40) !default; // 60px
$spacing-6xl: ($spacing-base + 40) !default; // 60px





// ==========================================================================
// FOR TESTING DO NOT USE
// ==========================================================================

// $spacing-4dpi-1: 4px;
// $spacing-4dpi-2: 12px;
// $spacing-4dpi-4: 20px;
// $spacing-4dpi-6: 28px;
// $spacing-4dpi-8: 36px;
// $spacing-4dpi-10: 44px;
// $spacing-4dpi-12: 52px;
// $spacing-4dpi-14: 60px;
// $spacing-4dpi-16: 68px;

// $spacing-ui-0: 0px;
// $spacing-ui-1: 2px;
// $spacing-ui-2: 4px;
// $spacing-ui-3: 8px;
// $spacing-ui-4: 16px;
// $spacing-ui-5: 24px;
// $spacing-ui-6: 32px;
// $spacing-ui-7: 40px;
// $spacing-ui-8: 48px;
// $spacing-ui-9: 56px;
// $spacing-ui-10: 64px;
// $spacing-ui-11: 72px;
// $spacing-ui-12: 80px;
// $spacing-ui-13: 88px;
// $spacing-ui-14: 96px;
// $spacing-ui-15: 104px;


$spacing-1: rem(2px);
$spacing-2: rem(4px);
$spacing-3: rem(8px);
$spacing-4: rem(16px);
$spacing-5: rem(24px);
$spacing-6: rem(32px);
// $spacing-6: rem(40px);
$spacing-7: rem(48px);
// $spacing-8: rem(56px);
// $spacing-9: rem(64px);
$spacing-8: rem(72px);
// $spacing-11: rem(80px);
// $spacing-12: rem(88px);
$spacing-9: rem(96px);
// $spacing-14: rem(104px);
// $spacing-15: rem(120px);
$spacing-10: rem(128px);
// $spacing-17: rem(136px);

$spacing-2xs: 2px;
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-base: 16px;
$spacing-lg: 24px;
$spacing-2xl: 32px;
// $spacing-6: 40px;
$spacing-3xl: 48px;
// $spacing-8: 56px;
// $spacing-9: 64px;
$spacing-4xl: 72px;
// $spacing-11: 80px;
// $spacing-12: 88px;
$spacing-5xl: 96px;
// $spacing-14: 104px;
// $spacing-15: 120px;
$spacing-6xl: 128px;
// $spacing-17: 136px;
