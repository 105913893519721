/* ==========================================================================
 * #SHADOWS
 * --------------------------------------------------------------------------
 * Box Shadow utilities.
 * ========================================================================== */

// .u-shadow1 { box-shadow: $boxShadow-1; }
// .u-shadow2 { box-shadow: $boxShadow-2; }
// .u-shadow3 { box-shadow: $boxShadow-3; }
// .u-shadow4 { box-shadow: $boxShadow-4; }
// .u-shadow5 { box-shadow: $boxShadow-5; }
