/* ==========================================================================
 * #BORDERS
 * --------------------------------------------------------------------------
 * Border utilities
 * ========================================================================== */

.u-borderAll { border-style: solid !important; border-width: 1px !important; }
.u-borderTop { border-top-style: solid !important; border-top-width: 1px !important; }
.u-borderRight { border-right-style: solid !important; border-right-width: 1px !important; }
.u-borderBottom { border-bottom-style: solid !important; border-bottom-width: 1px !important; }
.u-borderLeft { border-left-style: solid !important; border-left-width: 1px !important; }

.u-borderDotted { border-style: dotted !important; }
.u-borderDashed { border-style: dashed !important; }
.u-borderSolid {  border-style: solid !important; }

.u-borderTrans { border-color: transparent !important; }




/**
 * Border thickness
 */

.u-border0 { border-style: none !important; border-width: 0 !important; }
.u-border1 { border-width: 1px !important; }
.u-border2 { border-width: 2px !important; }
.u-border3 { border-width: 3px !important; }
.u-border4 { border-width: 4px !important; }
.u-border5 { border-width: 5px !important; }

// .u-radius0 { border-radius: 0 !important; }
// .u-radius1 { @include rem(border-radius, $borderRadius-base, important); }
// .u-radius2 { @include rem(border-radius, $borderRadius-lg, important); }
// .u-radius3 { @include rem(border-radius, $borderRadius-xl, important); }
// .u-radius4 { @include rem(border-radius, $borderRadius-xxl, important); }
// .u-radius5 { @include rem(border-radius, $borderRadius-xxxl, important); }

// Combine `.radius100` with a fixed height and width to make a circle
.u-radius100 { border-radius: 100%; }
.u-radius { @include rem(border-radius, $borderRadius-base, important); }

// .u-radius-pill {    border-radius: 9999px; }

// .u-radius--bottom {
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
//
// .u-radius--top {
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
// }
//
// .u-radius--right {
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
// }
//
// .u-radius--left {
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
// }


.u-borderColor-gray100 { border-color: $color-gray100; }
.u-borderColor-gray200 { border-color: $color-gray200; }
.u-borderColor-gray300 { border-color: $color-gray300; }
.u-borderColor-gray400 { border-color: $color-gray400; }
.u-borderColor-gray500 { border-color: $color-gray500; }
.u-borderColor-gray600 { border-color: $color-gray600; }
.u-borderColor-gray700 { border-color: $color-gray700; }
.u-borderColor-gray800 { border-color: $color-gray800; }
.u-borderColor-gray900 { border-color: $color-gray900; }

.u-borderColor-blue100 { border-color: $color-blue100; }
.u-borderColor-blue200 { border-color: $color-blue200; }
.u-borderColor-blue300 { border-color: $color-blue300; }
.u-borderColor-blue400 { border-color: $color-blue400; }
.u-borderColor-blue500 { border-color: $color-blue500; }
.u-borderColor-blue600 { border-color: $color-blue600; }
.u-borderColor-blue700 { border-color: $color-blue700; }
.u-borderColor-blue800 { border-color: $color-blue800; }
.u-borderColor-blue900 { border-color: $color-blue900; }

.u-borderColor-gold100 { border-color: $color-gold100; }
.u-borderColor-gold200 { border-color: $color-gold200; }
.u-borderColor-gold300 { border-color: $color-gold300; }
.u-borderColor-gold400 { border-color: $color-gold400; }
.u-borderColor-gold500 { border-color: $color-gold500; }
.u-borderColor-gold600 { border-color: $color-gold600; }
.u-borderColor-gold700 { border-color: $color-gold700; }
.u-borderColor-gold800 { border-color: $color-gold800; }
.u-borderColor-gold900 { border-color: $color-gold900; }
