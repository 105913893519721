/* ==========================================================================
 * Colors
 * --------------------------------------------------------------------------
 * Global color variables.
 * ========================================================================== */

/**
 * Helper Grayscale
 *
 * Use these when you ned a shade of gray that's not part of the main brand
 * grayscale.
 */

$color-white: #fff !default;
$color-black1: darken($color-white, 1%) !default;
$color-black2: darken($color-white, 2%) !default;
$color-black3: darken($color-white, 3%) !default;
$color-black4: darken($color-white, 4%) !default;
$color-black5: darken($color-white, 5%) !default;
$color-black10: darken($color-white, 10%) !default;
$color-black20: darken($color-white, 20%) !default;
$color-black30: darken($color-white, 30%) !default;
$color-black40: darken($color-white, 40%) !default;
$color-black50: darken($color-white, 50%) !default;
$color-black60: darken($color-white, 60%) !default;
$color-black70: darken($color-white, 70%) !default;
$color-black80: darken($color-white, 80%) !default;
$color-black90: darken($color-white, 90%) !default;
$color-black: #101010 !default;
$color-blackish: #101010 !default;


$color-gray100: #FEFEFE;
$color-gray200: #FAFAFA;
$color-gray300: #F1F1F1;
$color-gray400: #E0E0E0;
$color-gray500: #C7C7C7;
$color-gray600: #AEAEAE;
$color-gray700: #949494;
$color-gray800: #7B7B7B;
$color-gray900: #515151;

$color-blue100: #EFF3F9;
$color-blue200: #B3C4E0;
$color-blue300: #7796C7;
$color-blue400: #537AB8;
$color-blue500: #3F6299;
$color-blue600: #304B75;
$color-blue700: #213451;
$color-blue800: #121D2D;
$color-blue900: #040609;

$color-gold100: #FAF7F3;
$color-gold200: #F5EFE7;
$color-gold300: #E6D8C2;
$color-gold400: #D8C09E;
$color-gold500: #C9A97A;
$color-gold600: #BA9256;
$color-gold700: #9D7740;
$color-gold800: #795C31;
$color-gold900: #554022;


$color-success:   #5cb85c !default;
// $color-success:   #58ce8a !default;
$color-info:      #5bc0de !default;
$color-warning:   #eaa138 !default;
$color-danger:    #ed4337 !default;
$color-error:    #ed4337 !default;

$color-highlight:   rgba(255, 255, 255, .05) !default;
$color-shadow:      rgba(255, 255, 255, .1) !default;
$color-shadowInset: rgba(34, 40, 52, .7) !default;



/**
 * Links
 */

$color-link:          $color-blue500 !default;
$color-linkHover:     $color-blue600 !default;

$color-linkDark:          $color-gray800 !default;
$color-linkDarkHover:     $color-gray900 !default;

$color-linkLight:          $color-gray300 !default;
$color-linkLightHover:     $color-gray100 !default;

/**
 * Scafolding
 */

$color-bodyBackground:   $color-white !default;
$color-bodyText:         $color-blackish !default;


/**
 * Social media colors
 */

$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-googleplus: #dd4b39;
$color-pinterest: #cb2027;
$color-linkedin: #0077b5;
$color-youtube: #e62117;
$color-vimeo: #1ab7ea;
$color-tumblr: #32506d;
$color-instagram: #bc2a8d;
$color-flickr: #ff0084;
