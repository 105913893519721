/* ==========================================================================
 * #LINKS
 * --------------------------------------------------------------------------
 * Link utilities
 * ========================================================================== */

.u-linkLightest {
  color: $color-gray100;

  &:hover { color: darken($color-gray100, 12%); }
}

.u-linkLighter {
  color: $color-gray400;

  &:hover { color: darken($color-gray400, 12%); }
}

.u-linkLight {
  color: $color-gray500;

  &:hover { color: darken($color-gray500, 12%); }
}

.u-linkDark {
  color: $color-gray600;

  &:hover { color: lighten($color-gray600, 12%); }
}

.u-linkDarker {
  color: $color-gray700;

  &:hover { color: lighten($color-gray700, 12%); }
}

.u-linkDarkest {
  color: $color-gray900;

  &:hover { color: lighten($color-gray900, 12%); }
}
