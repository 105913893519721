/* ==========================================================================
 * Shame
 * --------------------------------------------------------------------------
 * This is where you place your hacks and filth, if you must.
 * ========================================================================== */

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*
 * See the Getting Started docs for more information:
 * http://getbootstrap.com/getting-started/#support-ie10-width
 */
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}



html {
  font-size: 100%;
  // font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (2800 - 300)));
}

@include breakpointUp(medium) {
  html { font-size: 110%; }
}

@include breakpointUp(large) {
  html { font-size: 120%; }
}

@include breakpointUp(larger) {
  html { font-size: 130%; }
}

body {
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  padding-bottom: $spacing-sm;
  transition: padding 300ms linear;
  // max-width: 1600px;
  // margin: 0 auto;

  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.55;
  color: #333;
}



@include breakpointUp(medium) {
  body {
    padding-left: $spacing-base;
    padding-right: $spacing-base;
    padding-bottom: $spacing-base;
  }
}

@include breakpointUp(large) {
  body {
    padding-left: $spacing-lg;
    padding-right: $spacing-lg;
    padding-bottom: $spacing-lg;
  }
}

@include breakpointUp(larger) {
  body {
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
    padding-bottom: $spacing-xl;
  }
}




.siteHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;

  flex-direction: column;

  @include breakpointUp(large) {
    flex-direction: row;
  }

  // padding-left: $spacing-xl;
  // padding-right: $spacing-xl;
}

.navigation {
  // background-color: $color-gray300;
  // display: none;
  max-height: 0;
  height: auto;
  overflow: hidden;
  width: 100%;
  margin: 0;
  border-top: 1px solid $color-gray300;
  border-bottom: 1px solid $color-gray300;
  transition: max-height 400ms ease-in-out;
}

.navToggle {
  margin-bottom: 16px;
}

.navOpen {
  .navigation {
    // height: auto;
    max-height: 500px;
    border-top-color: transparent;
  }

  .navToggle {
    background-color: $color-blue200;

  }
}

.navigation-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // max-width: 1200px;
  // margin: 0 auto;
  // flex-wrap: wrap;

  @include breakpointUp(large) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.navigation-item {
  // border-bottom: 1px solid $color-gray500;
  // margin-bottom: 4px;

  width: 100%;

  a {
    display: block;
    padding: 16px 8px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid transparent;
    }

    &.is-active {
      border-bottom: 2px solid transparent;
      background-color: $color-gold100;
    }
  }
}

@include breakpointUp(large) {
  .navigation {
    max-height: 100%;
  }

  .navigation-item {
    // border-bottom: 1px solid $color-gray500;
    // margin-bottom: 4px;
    width: auto;

    a {
      display: block;
      padding: 24px 16px;

      &:hover {
        border-bottom: 2px solid $color-gray500;
      }

      &.is-active {
        border-bottom: 2px solid $color-blue500;
        background-color: transparent;
      }
    }
  }
}






.u-inlineIcon {
  position: relative;
  top: -2px;
}





.module--gray {
  background-color: #FAFAFA;
}

.module {
  padding: $spacing-base;
  // min-height: 360px;
  // margin-bottom: $spacing-base;

  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
  }

  // @include breakpointUp(medium) {
  //
  // }
  //
  // @include breakpointUp(large) {
  //   // min-height: 430px;
  // }
  //
  // @include breakpointUp(larger) {
  //   // min-height: 630px;
  // }
  //
  // @include breakpointUp($breakpoint-4) {
  //   // min-height: 840px;
  // }
}

.intro {
  min-height: 400px;



  h1 {
    text-align: center;
    font-size: $fontSize-lg;
  }

  @include breakpointUp(small) {
    // min-height: 300px;

    h1 {
      font-size: $fontSize-lg;
    }

    // .row {
    //   max-width: 70vw;
    //   padding: 0 40px;
    // }
  }

  @include breakpointUp(medium) {
    min-height: 500px;

    h1 {
      font-size: $fontSize-xl;
    }

    // .row {
    //   max-width: 70vw;
    //   padding: 0 40px;
    // }
  }

  @include breakpointUp(large) {
    // min-height: 430px;
    min-height: 640px;

    .row {
      max-width: 70vw;
      padding: 0 40px;
    }

    h1 {
      font-size: $fontSize-2xl;
      max-width: 1300px;
    }
  }

  // @include breakpointUp(larger) {
  //   h1 {
  //     // font-size: $fontSize-4xl;
  //   }
  // }

  // @include breakpointUp($breakpoint-4) {
  //   // min-height: 840px;
  // }
}

.aboutUs {
  min-height: 300px;
  background: url(../../assets/images/photos/group-wide.jpg) no-repeat center;
  background-size: cover;

  @include breakpointUp(medium) {
    min-height: 400px;
  }

  @include breakpointUp(large) {
    min-height: 600px;
  }

  @include breakpointUp(larger) {
    min-height: 700px;
  }

  @include breakpointUp(largest) {
    min-height: 960px;
  }
}


.grid {
  display: flex;
}

.card {
  padding: $spacing-lg;
  text-align: center;
}

@include breakpointUp(large) {
  .sideNav {
    position: -webkit-sticky;
    position: sticky;
    top: 16px;
  }
}


.photoStrip {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;

  @include breakpointUp(large) {
    flex-wrap: nowrap;
  }

  div {
    flex-grow: 1;
    // display: none;
  }
}




// @include breakpointDown(1250px) {
  // .splitPhoto-img {
  //   height: 100%;
  //   background: url('../../assets/images/photos/group-002.jpg') no-repeat;
  //   background-size: cover;
  // }
// }


.splitPhoto-copy {
  height: 100%;
}
.splitPhoto-copyInner {
  max-width: 700px;
}
